import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  courseListIcon,
  courseListLightIcon,
  courseListSecondaryIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import {
  IReport,
  IReportContainerDispatch,
  IReportContainerState,
  IReportListMeta,
  IReportsDetails,
  IEnhancedTableProps,
  IReportPagePayloadValues,
  IEnrolmentReportStudentDetailsList,
  IEnrolmentReportFilter,
} from "utils/interfaces/report";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  base64ToArrayBuffer,
  calculateAge,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { IRoleModulePermission } from "store/role/types";
import {
  GetEnrolmentReportStudentDetailsListSuccessPayload,
  PrintEnrolmentReportSuccessPayload,
  ReportListSuccessPayload,
} from "store/report/types";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  STUDENT_DETAILS_ENROLMENT_REPORT_TOOLTIP_TITLE,
} from "utils/constants/constant";
import { toast } from "react-toastify";
import NoRecords from "components/NoRecords";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import OverlayBox from "components/OverlayBox";
import { EnrolmentOptionListSuccessPayload } from "store/studentEnrolment/types";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { ExportToCsv } from "export-to-csv";
import ReportFilter from "./ReportFilter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const {
    order,
    orderBy,
    onRequestSort,
    fieldName,
    keyName,
    sxStyle,
    children,
  } = props;
  const createSortHandler =
    (newOrderBy: keyof IReportsDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      <Box component="span" onClick={createSortHandler(keyName)}>
        {fieldName}
      </Box>
      {children}
      <Box
        component="span"
        onClick={createSortHandler(keyName)}
        className="sorting-icon"
      />
    </TableCell>
  );
};

export type ReportProps = IReportContainerState & IReportContainerDispatch;

const StudentReports: React.FC<ReportProps> = (props) => {
  const [reportList, setReportList] = useState<IReport[]>();
  const [reportListMeta, setReportListMeta] = useState<IReportListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IReportsDetails>("termName");
  const [searchText, setSearchText] = useState<string>("");
  const [filterBox, setFilterBox] = useState(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IEnrolmentReportFilter>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();
  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };
  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    const { enrolmentOptionListRequest } = props;
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };
  const onPageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IReportsDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onReportListSuccess = (response: ReportListSuccessPayload) => {
    setReportList(response.reportList);
    setReportListMeta(response.reportListPagination);
    hideLoaderForBatchApiCall();
  };

  const getReportList = async (
    filterData?: IEnrolmentReportFilter,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { reportListRequest } = props;

    if (reportListRequest) {
      showLoaderForBatchApiCall();

      const values: IReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.termName = filtered ? "" : searchText.trim();

      if (filterData?.enrolmentType) {
        values.enrolmentType = filterData?.enrolmentType;
      }
      if (filterData?.sector) {
        values.sector = filterData?.sector;
      }
      if (filterData?.age) {
        values.age = filterData?.age;
      }
      if (filterData?.gender) {
        values.gender = filterData?.gender;
      }
      if (filterData?.aTSI) {
        values.aTSI = filterData?.aTSI;
      }
      if (filterData?.employmentStatus) {
        values.employmentStatus = filterData?.employmentStatus;
      }
      if (filterData?.studyLoad) {
        values.studyLoad = filterData?.studyLoad;
      }
      if (filterData?.terms) {
        values.terms = filterData?.terms;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onReportListSuccess,
      };
      reportListRequest(payload);
    }
  };

  useEffect(() => {
    getEnrolmentOptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Reports);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getReportList(filteredData, true, 1);
      } else if (filteredData) {
        getReportList(filteredData);
      } else {
        getReportList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleFilterApplyClick = (formData: IEnrolmentReportFilter) => {
    if (
      !formData.terms?.length &&
      !formData.enrolmentType?.length &&
      !formData.sector?.length &&
      !formData.age?.length &&
      !formData.gender?.length &&
      !formData.aTSI?.length &&
      !formData.employmentStatus?.length &&
      !formData.studyLoad?.length
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText("");
      if (pageNo === DEFAULT_PAGE_NO) {
        getReportList(formData, true, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  const [exportMenu, setExportMenu] = React.useState<null | HTMLElement>(null);
  const exportOpen = Boolean(exportMenu);
  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenu(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenu(null);
  };
  const csvFileHeaders = [
    "Term",
    "Year",
    "No. of Enrolments",
    "Consumer",
    "Carer",
    "Partner Employee",
    "Staff",
    "Volunteer",
    "Other",
  ];

  const csvFileHeadersStudentDetails = [
    "Name",
    "Email",
    "Phone",
    "Address",
    "Ethnicity",
    "Gender Identity",
    "Enrolment Type",
    "Sector",
    "Age",
    "Employment Status",
    "Study Load",
    "Aboriginal/Torres Strait Islander Identity",
  ];

  const getEnrolmentsModifiedData = (list: IReport[]) => {
    const data = list?.map((report: IReport) => {
      return {
        Term: report.termName,
        Year: report.year,
        "No. of Enrolments": report.noEnrolled,
        Consumer: report.consumer,
        Carer: report.career,
        "Partner Employee": report.partnerEmployee,
        Staff: report.staff,
        Volunteer: report.volunteer,
        Other: report.other,
      };
    });

    return data;
  };

  const onExportExcelEnrolmentListSuccess = (
    response: ReportListSuccessPayload
  ) => {
    const modifiedEnrolmentsData = getEnrolmentsModifiedData(
      response.reportList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Enrolment Report",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedEnrolmentsData);
  };

  const getExportExcelEnrolments = () => {
    const { reportListRequest } = props;

    if (reportListRequest) {
      showLoader();

      const values: IReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.termName = "";

      if (filteredData?.enrolmentType) {
        values.enrolmentType = filteredData?.enrolmentType;
      }
      if (filteredData?.sector) {
        values.sector = filteredData?.sector;
      }
      if (filteredData?.age) {
        values.age = filteredData?.age;
      }
      if (filteredData?.gender) {
        values.gender = filteredData?.gender;
      }
      if (filteredData?.aTSI) {
        values.aTSI = filteredData?.aTSI;
      }
      if (filteredData?.employmentStatus) {
        values.employmentStatus = filteredData?.employmentStatus;
      }
      if (filteredData?.studyLoad) {
        values.studyLoad = filteredData?.studyLoad;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }

      const payload = {
        values,
        callback: onExportExcelEnrolmentListSuccess,
      };
      reportListRequest(payload);
    }
  };

  const onPrintEnrolmentReportSuccess = (
    response: PrintEnrolmentReportSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintEnrolmentReport = () => {
    const { printEnrolmentReportRequest } = props;

    if (printEnrolmentReportRequest) {
      showLoader();

      const values: IReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.termName = "";

      if (filteredData?.enrolmentType) {
        values.enrolmentType = filteredData?.enrolmentType;
      }
      if (filteredData?.sector) {
        values.sector = filteredData?.sector;
      }
      if (filteredData?.age) {
        values.age = filteredData?.age;
      }
      if (filteredData?.gender) {
        values.gender = filteredData?.gender;
      }
      if (filteredData?.aTSI) {
        values.aTSI = filteredData?.aTSI;
      }
      if (filteredData?.employmentStatus) {
        values.employmentStatus = filteredData?.employmentStatus;
      }
      if (filteredData?.studyLoad) {
        values.studyLoad = filteredData?.studyLoad;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }

      const payload = {
        values,
        callback: onPrintEnrolmentReportSuccess,
      };
      printEnrolmentReportRequest(payload);
    }
  };

  const getEnrolmentReportStudentDetailsModifiedData = (
    list: Array<IEnrolmentReportStudentDetailsList>
  ) => {
    const data = list?.map((details: IEnrolmentReportStudentDetailsList) => {
      return {
        Name: details.studentName,
        Email: details.email,
        Phone: `="${details.phone}"`,
        Address: details.address,
        Ethnicity: details.ethnicity || "-",
        "Gender Identity": details.genderIdentity || "-",
        "Enrolment Type": details.enrolmentType || "-",
        Sector: details.sector || "-",
        Age: calculateAge(details.dateOfBirth) || "-",
        "Employment Status":
          enrolmentOptionList?.employmentStatus.find(
            (es) => es.value === details.employmentStatus
          )?.label || "-",
        "Study Load": details.studyLoad || "-",
        "Aboriginal/Torres Strait Islander Identity":
          details.atsiIdentity || "-",
      };
    });

    return data;
  };

  const onEnrolmentReportStudentDetailsSuccess = (
    response: GetEnrolmentReportStudentDetailsListSuccessPayload
  ) => {
    const modifiedStudentDetailsData =
      getEnrolmentReportStudentDetailsModifiedData(
        response.enrolmentReportStudentDetailList
      );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeadersStudentDetails,
      filename: "Student Details",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedStudentDetailsData);
  };

  const getEnrolmentReportStudentDetails = (termId?: number) => {
    const { getEnrolmentReportStudentDetailsRequest } = props;

    if (getEnrolmentReportStudentDetailsRequest) {
      showLoader();
      const values: IReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.termName = "";

      if (filteredData?.enrolmentType) {
        values.enrolmentType = filteredData?.enrolmentType;
      }
      if (filteredData?.sector) {
        values.sector = filteredData?.sector;
      }
      if (filteredData?.age) {
        values.age = filteredData?.age;
      }
      if (filteredData?.gender) {
        values.gender = filteredData?.gender;
      }
      if (filteredData?.aTSI) {
        values.aTSI = filteredData?.aTSI;
      }
      if (filteredData?.employmentStatus) {
        values.employmentStatus = filteredData?.employmentStatus;
      }
      if (filteredData?.studyLoad) {
        values.studyLoad = filteredData?.studyLoad;
      }
      if (filteredData?.studyLoad) {
        values.terms = filteredData?.terms;
      }
      if (termId) {
        values.termId = termId;
      }

      const payload = {
        values,
        callback: onEnrolmentReportStudentDetailsSuccess,
      };

      getEnrolmentReportStudentDetailsRequest(payload);
    }
  };

  return (
    <>
      <>
        <div className="wrapper">
          <Box
            className="overlay"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
          />
          <Box
            className="filter-overlay"
            onClick={() => {
              document.body.classList.toggle("filter-open");
              setFilterBox(false);
            }}
          />
          {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Report - Enrolments
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                id="export-btn"
                aria-controls={exportOpen ? "export-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={exportOpen ? "true" : undefined}
                onClick={handleExportClick}
                disableElevation
                variant="outlined"
                className="btn-export"
              >
                Export
              </Button>
              <Menu
                id="export-menu"
                anchorEl={exportMenu}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "export-button",
                }}
                className="export-menu"
              >
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getExportExcelEnrolments}
                  title="Excel"
                >
                  <Button>Excel</Button>
                </MenuItem>
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getPrintEnrolmentReport}
                  title="PDF"
                >
                  <Button>PDF</Button>
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                className="btn-filter report-filter"
                onClick={() => {
                  setFilterBox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                <span className="text">Filter</span>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "termName")
                          }
                          fieldName="Term"
                          keyName="termName"
                          sxStyle={{
                            width: "170px",
                            minWidth: "140px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "year")}
                          fieldName="Year"
                          keyName="year"
                          sxStyle={{
                            width: "106px",
                            minWidth: "106px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "noEnrolled")
                          }
                          fieldName="Total"
                          keyName="noEnrolled"
                          sxStyle={{
                            width: "206px",
                            minWidth: "130px",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={
                              STUDENT_DETAILS_ENROLMENT_REPORT_TOOLTIP_TITLE
                            }
                            arrow
                          >
                            <IconButton
                              disabled={
                                (reportList && reportList.length <= 0) ||
                                !pagePermission?.view
                              }
                              onClick={() =>
                                reportList && reportList.length <= 0
                                  ? null
                                  : getEnrolmentReportStudentDetails()
                              }
                            >
                              <img
                                src={courseListSecondaryIcon}
                                alt="Details"
                              />
                            </IconButton>
                          </Tooltip>
                        </EnhancedTableHead>

                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "consumer")
                          }
                          fieldName="Consumer"
                          keyName="consumer"
                          sxStyle={{
                            width: "206px",
                            minWidth: "130px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "carer")}
                          fieldName="Carer"
                          keyName="carer"
                          sxStyle={{
                            width: "136px",
                            minWidth: "136px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "partnerEmployee")
                          }
                          fieldName="Partner Employee"
                          keyName="partnerEmployee"
                          sxStyle={{
                            width: "206px",
                            minWidth: "130px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "staff")}
                          fieldName="Staff"
                          keyName="staff"
                          sxStyle={{
                            width: "136px",
                            minWidth: "136px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "volunteer")
                          }
                          fieldName="Volunteer"
                          keyName="volunteer"
                          sxStyle={{
                            width: "206px",
                            minWidth: "130px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "other")}
                          fieldName="Other"
                          keyName="other"
                          sxStyle={{
                            width: "136px",
                            minWidth: "136px",
                            textAlign: "center",
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportList?.map((row) => (
                        <TableRow key={row.termId}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row.termName}
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              className="email ellipsis-text"
                            >
                              {row.year}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={
                                STUDENT_DETAILS_ENROLMENT_REPORT_TOOLTIP_TITLE
                              }
                              arrow
                            >
                              <Button
                                className="btn-course"
                                variant="outlined"
                                disabled={
                                  !row.noEnrolled || !pagePermission?.view
                                }
                                onClick={() =>
                                  !row.noEnrolled
                                    ? null
                                    : getEnrolmentReportStudentDetails(
                                        row.termId
                                      )
                                }
                              >
                                <img
                                  src={
                                    !row.noEnrolled
                                      ? courseListSecondaryIcon
                                      : courseListIcon
                                  }
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row.noEnrolled}
                                </Typography>
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">{row.consumer}</TableCell>
                          <TableCell align="center">{row.career}</TableCell>
                          <TableCell align="center">
                            {row.partnerEmployee}
                          </TableCell>
                          <TableCell align="center">{row.staff}</TableCell>
                          <TableCell align="center">{row.volunteer}</TableCell>
                          <TableCell align="center">{row.other}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {reportList && reportList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(reportListMeta, pageNo, page)}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={reportListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>

      {filterBox && (
        <ReportFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          setAppliedFilter={setAppliedFilter}
          getReportList={getReportList}
          enrolmentOptionList={enrolmentOptionList}
        />
      )}
    </>
  );
};

export default StudentReports;
